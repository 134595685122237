import 'lazysizes';

const _si = (selector, context = document, returnNull = false) => {
    const tmp = context.querySelector(selector);
    return tmp ? tmp : returnNull ? null : _c('div');
};
const _s = (selector, context = document) => {
    return Array.apply(null, context.querySelectorAll(selector));
};

const _c = elm => {
    return document.createElement(elm);
};

const _ael = (sel, ev, callback) => {
    document.addEventListener(ev, e => {
        if (e.target === document) {
            return;
        }
        const that = e.target.closest(sel);
        if (that) {
            callback(that, e);
        }
    });
};

// App main
const main = async () => {
    const sections = {
        festival: _si('.js--festival', document, true),
        conference: _si('.js--conference', document, true),
        speakers: _si('.js--speakers', document, true)
    };
    let window_width = 0,
        artister,
        scener;

    _ael('.js--menutoggle', 'click', () => {
        document.body.classList.toggle('css--show-menu');
    });

    _ael('.js--click', 'click', elm => {
        elm.querySelector('a').click();
    });

    function initTicker() {
        var text = _si('.js--ticker span', document, true);
        if (text) {
            var box = text.getBoundingClientRect();
            for (var i = 0; i < Math.ceil(window_width / box.width); i++) {
                var clone = text.cloneNode(true);
                _si('.js--ticker').appendChild(clone);
            }

            var s =
                '@keyframes ticker {' +
                '0% { margin-left: 0; }' +
                '100% { margin-left: -' +
                (box.width + 9) +
                'px' +
                '}';
            text.style.animation = 'ticker 10s linear infinite';
            document.head.insertAdjacentHTML('beforeend', '<style>' + s + '</style>');
        }
    }

    _ael('.js--festival-nav button', 'click', elm => {
        sortArtists(elm.getAttribute('data-id'), 'festival');
        elm.classList.add('bg-red');
    });
    _ael('.js--festival-nav select', 'change', elm => {
        sortArtists(elm.value, 'festival');
        elm.parentNode.classList.add('bg-red');
    });

    _ael('.js--conference-nav button', 'click', elm => {
        sortArtists(elm.getAttribute('data-id'), 'conference');
        elm.classList.add('bg-red');
    });
    _ael('.js--conference-nav select', 'change', elm => {
        sortArtists(elm.value, 'conference');
        elm.parentNode.classList.add('bg-red');
    });

    function initAppmiral(type) {
        const headers = {
            headers: {
                'x-protect': 'd6c44a15d3b5a81785a5004f3eba2b0d'
            }
        };
        const urlType = type === 'speakers' ? 'conference' : type;
        Promise.all([
            fetch(
                `https://app.appmiral.com/api/v6/events/trondheimcalling/editions/trondheimcalling2022/artists?child_edition=trondheimcalling2022${urlType}&maxperpage=300`,
                headers
            ).then(res => res.json()),
            fetch(
                `https://app.appmiral.com/api/v6/events/trondheimcalling/editions/trondheimcalling2022/stages?child_edition=trondheimcalling2022${urlType}&maxperpage=300`,
                headers
            ).then(res => res.json())
        ]).then(res => {
            scener = res[1].data;
            artister = res[0].data.map(a => {
                return {
                    ...a,
                    performances: a.performances.map(p => {
                        return {
                            ...p,
                            stage: scener.find(s => s.id === p.stage_id)
                        };
                    })
                };
            });
            if (type === 'speakers') {
                artister = artister.filter(a => a.show_in_artists);
            } else if (type === 'conference') {
                artister = artister.filter(a => !a.show_in_artists);
            }

            const urlParts = location.pathname.split('/');
            if (urlParts.includes('spilleplan')) {
                _si(`.js--${type}-nav button.bg-red`).click();
            } else if (urlParts.length > 2 && urlParts[2].length) {
                const id = parseInt(location.pathname.split('/')[2]);
                const artist = artister.find(a => a.id === id);
                sections[type].innerHTML = artistTemplate(artist, type);
            } else if (location.hash.length > 2) {
                let hash = location.hash.replace('#', '');
                hash = hash.substr(hash.indexOf('date=') + 5, 2);
                const select = _si(`.js--${type}-nav select`);
                select.value = 'd-' + hash;
                const evt = document.createEvent('HTMLEvents');
                evt.initEvent('change', true, false);
                select.dispatchEvent(evt);
            } else if (type === 'speakers') {
                sortArtists('all', type);
            } else {
                _si(`.js--${type}-nav button.bg-red`).click();
            }
        });
    }

    function initArtister() {
        initAppmiral('festival');
    }

    function initKonferanse() {
        initAppmiral('conference');
    }

    function initSpeakers() {
        initAppmiral('speakers');
    }

    function sortArtists(val, type) {
        let html = [];
        _s(`.js--${type}-nav > *`).forEach(elm => {
            elm.classList.remove('bg-red');
            elm.classList.add('bg-black');
        });
        if (val === 'all') {
            _si('.js--artist-nav select').value = 'all';
            artister.sort((a, b) => {
                return a.priority < b.priority ? -1 : 1;
            });
            html = artister.map(a => artistListTemplate(a, type));
            sections[type].innerHTML = html.join('');
            location.hash = '';
        } else {
            const date = val.substring(2);
            location.hash = 'date=' + date;
            const perfs = [];
            artister.forEach(artist => {
                artist.performances
                    .filter(p => p.stage && p.start_time.includes(`2023-02-${date}`))
                    .forEach(p => {
                        let perf = perfs.find(x => x.id === p.stage.id);
                        if (!perf) {
                            perf = {
                                id: p.stage.id,
                                name: p.stage.name,
                                priority: p.stage.priority,
                                color: p.stage.color || 'black',
                                artists: []
                            };
                            perfs.push(perf);
                        }
                        perf.artists.push({
                            name: artist.name,
                            id: artist.id,
                            lineup_image: artist.lineup_image,
                            perf: p,
                            priority: artist.priority
                        });
                    });
            });
            perfs.sort((a,b) => {
                return a.priority < b.priority ? -1 : 1;
            })
            perfs.forEach(stage => {
                let startHour = 10;
                stage.artists.sort((a, b) => {
                    return a.perf.start_time < b.perf.start_time ? -1 : 1;
                });
                if (type === 'conference') {
                    // stage.artists.sort((a, b) => {
                    //     return a.priority < b.priority ? -1 : 1;
                    // });
                } else {
                    // weird custom check for Urørt-perf på fredag:
                    
                    if (date === '10') {
                        startHour = 16;
                        _si('.css--times').style.transform = '';
                    } else {
                        startHour = 18;
                        _si('.css--times').style.transform = 'translateY(-280px)';
                    }

                    // stage.artists.sort((a, b) => {
                    //     return a.perf.start_time < b.perf.start_time ? -1 : 1;
                    // });
                }
                if (location.pathname.includes('spilleplan')) {
                    html.push(
                        `<div class="w-40 flex-shrink-0 px-2 sm:w-2/5 md:w-[22%] sm:text-base">
                            <h2 class="inline-block text-white bg-black border px-2 py-1 my-4" style="background:${
                            stage.color || ''
                        }">
                                ${stage.name}
                            </h2>
                            <div class="relative">`
                    );
                    html = html.concat(
                        stage.artists.map(a =>
                            artistSpilleplanTemplate(a, type, a.perf, stage.color, startHour)
                        )
                    );
                    html.push('</div></div>');
                } else {
                    // Dette skal inn igjen i desember
                    html.push(
                        `<div class="w-full md:px-2">
                                <h2 class="inline-block bg-black text-white border px-2 py-1 my-4" style="background:${
                            stage.color || ''
                        }">
                                    ${stage.name}
                                </h2>
                            </div>`
                    );
                    html = html.concat(
                        stage.artists.map(a => artistListTemplate(a, type, a.perf))
                    );
                }
            });
            if (html.length) {
                sections[type].innerHTML = html.join('');
            } else {
                sections[type].innerHTML =
                    'Ingen opptredener satt opp på denne datoen foreløpig, kom tilbake litt senere og sjekk igjen.';
            }
        }
    }

    function artistSpilleplanTemplate(artist, type, perf, color, startHour) {
        let timestamp = '';
        const start = new Date(perf.start_time);
        const end = new Date(perf.end_time);
        timestamp = `<span class="block">${prettyHours(start)} – ${prettyHours(end)}</span>`;
        let top = (start.getHours() - startHour) * 140;
        top += start.getMinutes() * (140 / 60);
        let height = ((end - start) / 1000 / 60) * (140 / 60);
        const url = type === 'festival' ? 'artister' : 'konferanse';
        const img = artist.lineup_image ? artist.lineup_image['500'] : '//unsplash.it/500/500';
        return `<a href="/${url}/${artist.id}" class="absolute left-0 w-full mb-4 font-special border-l-4 border-purple shadow bg-white p-1" style="border-color:${color};top:${top}px;height:${height}px">
<!--            <img src='${img}' class='absolute right-0 top-0 h-full aspect-square object-cover opacity-50'>-->
            <span class="block relative">${artist.name}</span>
            <span class="block relative">${timestamp}</span>
        </a>`;
    }

    function artistListTemplate(artist, type, perf = null) {
        if (type === 'speakers' && artist.performances.length) {
            return;
        }
        let timestamp = '';
        // Dette skal inn igjen i desember
        if (perf) {
            const start = new Date(perf.start_time);
            const end = new Date(perf.end_time);
            timestamp = `<span class="bg-black text-white rounded-full border px-2 py-1">${prettyHours(
                start
            )} – ${prettyHours(end)} </span>`;
        }

        const img = artist.lineup_image ? artist.lineup_image['500'] : '//unsplash.it/500/500';
        const url = type === 'festival' ? 'artister' : type === 'conference' ? 'konferanse' : type;
        const widths =
            type === 'speakers'
                ? 'px-2 w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5'
                : 'w-full md:w-1/2 md:px-2 lg:w-1/3 xl:w-1/4';
        const padding = type === 'festival' ? 'pb-[77.47747748%]' : 'pb-[100%]';
        return `<a href="/${url}/${artist.id}" class="mb-4 relative ${widths}">
            <picture class="relative block ${padding}">
                <img src="${img}" class="absolute top-0 left-0 w-full h-full object-cover object-center">
            </picture>
            <div class="absolute inset-x-8 bottom-6 flex flex-wrap font-special text-sm">
                <span class="text-red bg-black px-2 py-1">${artist.name}</span>
                ${timestamp}
            </div>
        </a>`;
    }

    function artistTemplate(artist, type) {
        let perfs = '';
        // Dette skal inn igjen i desember
        artist.performances.forEach(perf => {
            const start = new Date(perf.start_time);
            const end = new Date(perf.end_time);
            perfs += `<div class="flex">
                <span class="bg-red border px-2 py-1 font-special">${prettyDate(start)}</span>
                <span class="border text-white px-2 py-1 font-special bg-black">${
                    perf.stage.name
                }</span>
                <span class="bg-black text-white rounded-full border px-2 py-1 font-special">  ${prettyHours(
                    start
                )} – ${prettyHours(end)} </span>
            </div>`;
        });
        let tags = '';
        artist.tags.forEach(tag => {
            tags += `<span class="bg-red border px-2 pt-1">${tag.name}</span>`;
        });
        let links = '';
        const linkNames = [
            { key: 'facebook_page_id', name: 'Facebook', prefix: 'https://facebook.com/' },
            {
                key: 'spotify_artist_id',
                name: 'Spotify',
                prefix: 'https://open.spotify.com/artist/'
            },
            { key: 'twitter_user', name: 'Twitter', prefix: 'https://twitter.com/' },
            { key: 'instagram_user', name: 'Instagram', prefix: 'https://instagram.com/' },
            { key: 'website', name: 'Website', prefix: '' },
            { key: 'youtube_channel_id', name: 'YouTube', prefix: 'https://youtube.com/channel/' }
        ];
        linkNames.forEach(link => {
            if (artist.links.hasOwnProperty(link.key)) {
                const url = link.prefix + artist.links[link.key];
                links += `<a href="${url}" class="bg-black border px-2 pt-1">${link.name}</a>`;
            }
        });
        let embed = '';
        if ('tracks' in artist && artist.tracks.length) {
            const width = sections[type].getBoundingClientRect().width;
            embed = `<iframe src="https://open.spotify.com/embed/track/${artist.tracks[0].track_id}" width="${width}" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media" class="mt-8"></iframe>`;
        }

        let youtube = '';
        let bio = artist.biography;
        if (artist.biography.includes('<iframe')) {
            youtube = bio.substr(bio.indexOf('<iframe'));
            youtube = youtube.substr(0, youtube.indexOf('</iframe>') + 9);
            bio = bio.replace(youtube, '');
            youtube = youtube.replace(
                '<iframe',
                '<iframe class="absolute top-0 left-0 w-full h-full'
            );
            youtube = '<div class="relative my-8 w-full pb-[56.25%]">' + youtube + '</div>';
        }
        let img = artist.lineup_image
            ? Object.values(artist.lineup_image)[Object.values(artist.lineup_image).length - 1]
            : '//unsplash.it/1080/1080';

        return `<article class="mb-5 relative w-full md:flex md:flex-wrap md:items-start">
            <img src="${img}" class="w-full md:w-1/2 md:pl-3">
            <div class="md:w-1/2 md:pr-3 md:order-first">
                <h1 class="font-special inline border-b-4 border-black text-4xl leading-normal md:leading-relaxed md:mb-12">
                    ${artist.name}
                </h1>
                <div class="css--bio font-sans text-2xl mb-8">${bio}</div>
                ${perfs}
                <div class="flex flex-wrap text-white font-special">${tags}</div>
                <div class="flex flex-wrap text-white font-special">${links}</div>
            </div>
            ${youtube}
            ${embed}
        </article>`;
    }

    function prettyDate(time) {
        const days = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];
        const day = time.getDay();
        const months = [
            'januar',
            'februar',
            'mars',
            'april',
            'mai',
            'juni',
            'juli',
            'august',
            'september',
            'oktober',
            'november',
            'desember'
        ];
        return `${days[day]} ${time.getDate()}. ${months[time.getMonth()]}`;
    }

    function prettyHours(time) {
        let h = time.getHours();
        let m = time.getMinutes();
        if (h < 10) {
            h = '0' + h;
        }
        if (m < 10) {
            m = '0' + m;
        }
        return h + ':' + m;
    }

    function initSlideshows() {
        if (typeof Swiper === 'undefined') {
            return setTimeout(initSlideshows, 100);
        }

        _s('.swiper-container').forEach(function (elm, i) {
            var newName = 'swiper-' + i;
            elm.classList.add(newName);

            var tmp = new Swiper('.' + newName, {
                centeredSlides: true,
                spaceBetween: 10,
                navigation: {
                    nextEl: '.' + newName + ' .swiper-button-next',
                    prevEl: '.' + newName + ' .swiper-button-prev'
                },
                pagination: {
                    el: '.' + newName + ' .swiper-pagination',
                    clickable: true
                }
            });
        });
    }

    function init() {
        window_width = window.innerWidth;
        initTicker();
        initSlideshows();

        if (sections.festival) {
            initArtister();
        }
        if (sections.conference) {
            initKonferanse();
        }
        if (sections.speakers) {
            initSpeakers();
        }
    }

    init();
};

// Execute async function
main();
